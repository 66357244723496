/**
 * Dynamic loading of script
 * @param src url of script
 * @param async async loading
 * @param defer
 */
function loadScript(src, async, defer) {
    async = async || false;
    defer = defer || false;

    var script_elem = $('<script/>', {
        src: src
    });

    if (async) {
        script_elem.attr('async', true);
    }

    if (defer) {
        script_elem.attr('defer', true);
    }

    $('head').append(script_elem);
}

function runArcticModal(text_modal, callback) {
    text_modal = text_modal || '';
    callback = callback || {};
    var $_modal = $('#standartModal');

    $_modal.find('.p_text').html(text_modal);
    $_modal.arcticmodal(callback);
}

function getGeoLocation(callback) {
    var coordinates = {
        latitude:  null,
        longitude: null
    };

    var success = function(position) {
        coordinates.latitude  = position.coords.latitude;
        coordinates.longitude = position.coords.longitude;
        if (typeof callback === 'function') {
            callback(coordinates);
        }
    };

    var error = function() {
        console.warn('Unable to retrieve your location');
    };

    if (typeof navigator !== 'undefined' && 'geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(success, error);
    } else {
        console.warn('Geolocation is not supported by your browser');
    }

    return coordinates;
}

if (!Array.isArray) {
    Array.isArray = function (arg) {
        return Object.prototype.toString.call(arg) === '[object Array]';
    };
}

(function ($, window, document, undefined) {
    $(document).ready(function () {
        var $_gallery = $('.p_gallery_wrap .p_gallery'),
            $_select = $('.tb_select'),
            $_menu = $('.m_carte > .m_link_wrap, .content_position.footer > .m_link_wrap'),
            $_office_menu = $('#lk_menu');

        if ($_gallery.length) {
            $_gallery.simpleLightbox();
        }
        if ($_select.length) {
            $_select.selectize();
        }

        /*if ($('.js-select').length) {
            $('.js-select').selectize();
        }*/


        if ($_office_menu.length) {
            var direction = location.pathname.replace(/\/{1,}/g, '');

            $_office_menu.find('a').each(function () {
                var tmp_href = jQuery(this).attr('href').replace(/\/{1,}/g, '');

                if (tmp_href == direction) {
                    jQuery(this).closest('.lk_tab').addClass('active');
                }
            });
        }
    }).on('click', '.p_link-toggle', function (e) {
        var caption = $(this),
            content = caption.prev();
        $(this).toggleClass('open');
        e.preventDefault();
        content.not($(this).prev(content)).addClass('open');
        $(this).prev(content).toggleClass('open');
    }).on('click', '.c-rate .c-rate__item .p_link.p_link-toggle', function (e) {
        e.preventDefault();
        var cur_index = $(this).closest('.c-rate__item').index();

        $('.c-rate .c-rate__item').removeClass('active');
        $(this).closest('.c-rate__item').addClass('active');

        $('.c-rate__more > div').addClass('p_text-hide').eq(cur_index).removeClass('p_text-hide');
    }).on('click', '#alarm_button', function (event) {
        event.preventDefault();

        var $_this = $(this);

        getGeoLocation(function (coordinates) {
            $.ajax({
                type: 'POST',
                url: '/alarmcalls/request/add',
                data: {
                    latitude:  coordinates.latitude,
                    longitude: coordinates.longitude
                },
                dataType: 'json',
                success: function (response) {
                    var text_modal = 'Ошибка. Попробуйте снова.';
                    if (response.success) {
                        text_modal = 'Вызов принят.';
                        $_this.remove();
                    }

                    runArcticModal(text_modal);
                }
            });
        });
    });
})(jQuery, window, document);